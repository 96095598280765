import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Table,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import BGAnimation from "../../assets/images/BGAnimation.png";
import Gallerybg from "../../assets/images/Gallerybg.png";
import Eventbg from "../../assets/images/Eventbg.png";
import CMEbg from "../../assets/images/CMEbg.png";
import Careerbg from "../../assets/images/Careerbg.png";
//Import Components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
//Import Image
import setupanalytics from "../../assets/images/setup-analytics-amico.svg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import LoadingOverlay from "react-loading-overlay";

const Dashboard = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_backdrop1, setmodal_backdrop1] = useState(false);
  const [association, setAssociation] = useState();
  const [doctorList, setDoctorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const doctorsPerPage = 5;
  const [profile, setProfile] = useState();
  const [cmeCount, setCmeCount] = useState();
  const [eventCount, setEventCount] = useState();
  const [galleryCount, setGalleryCount] = useState();
  const [careerCount, setCareerCount] = useState();
  const [associationCount, setAssociationCount] = useState();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");

    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/user`, requestOptions)
      .then((response) => response.json())
      .then((result) => setProfile(result))
      .catch((error) => console.log("error", error));
  }, []);
  const getUserByEmail = (email) => {
    if (!profile) return {};
    console.log(profile);
    let u = profile.find((u) => u.email === email);
    return u;
  };

  // Get doctor in the list
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/event/count`, requestOptions)
      .then((response) => response.json())
      .then((result) => setEventCount(result.count))
      .catch((error) => console.log("error", error));
    fetch(`${REACT_APP_API_ENDPOINT}/cme/count`, requestOptions)
      .then((response) => response.json())
      .then((result) => setCmeCount(result.count))
      .catch((error) => console.log("error", error));
    fetch(`${REACT_APP_API_ENDPOINT}/gallery/count`, requestOptions)
      .then((response) => response.json())
      .then((result) => setGalleryCount(result.count))
      .catch((error) => console.log("error", error));
    fetch(`${REACT_APP_API_ENDPOINT}/career/count`, requestOptions)
      .then((response) => response.json())
      .then((result) => setCareerCount(result.count))
      .catch((error) => console.log("error", error));
    fetch(`${REACT_APP_API_ENDPOINT}/association/count`, requestOptions)
      .then((response) => response.json())
      .then((result) => setAssociationCount(result.count))
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/user/profile`, {
      method: "GET",
      headers: { "x-api-key": jwt },
    })
      .then((response) => response.json())
      .then((result) => {
        setEmail(result.email);
      })
      .catch((error) => console.log("error", error));
  }, [jwt]);

  useEffect(() => {
    if (email) {
      fetch(
        `${REACT_APP_API_ENDPOINT}/president/association?email=${email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setAssociation(result))
        .catch((error) => console.log("error", error));
    }
  }, [email]);

  useEffect(() => {
    if (association && association._id) {
      setIsLoading(true);
      fetch(
        `${REACT_APP_API_ENDPOINT}/association/doctors?association=${association._id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // Filter the list based on the search query
          //const filteredList = result
          //);
          //console.log("docs", filteredList);
          setDoctorList(result);
          setIsLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [association]);

  // const fetchDoctors = () => {
  //   // Your API fetch logic here
  //   // Replace this with your actual API endpoint
  //   const apiUrl = `${REACT_APP_API_ENDPOINT}/doctor`;
  //   fetch(apiUrl, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => setDoctorList(data))
  //     .catch((error) => console.log("error", error));
  // };
  // useEffect(() => {
  //   fetchDoctors();
  // }, []);

  // Pagination logic

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = doctorList.slice(
    indexOfFirstDoctor,
    indexOfLastDoctor
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Association k numbers k liye get api here
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/association`, requestOptions)
      .then((response) => response.json())
      .then((result) => setAssociation(result))
      .catch((error) => console.log("error", error));
  }, []);

  /// For GET the event list(Events Number)
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/event`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Map events to FullCalendar compatible format
        const formattedEvents = result.map((event) => {
          return {
            title: event.name,
            start: event.startDateTime,
            end: event.lastDateTime,
          };
        });
        setFormattedEvents(formattedEvents);
      })
      .catch((error) => console.log("error", error));
  }, []);

  // for Opening of modal
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function tog_backdrop1() {
    setmodal_backdrop1(!modal_backdrop1);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <LoadingOverlay active={isLoading} spinner={<Spinner></Spinner>}>
        <div className="page-content">
          <Row>
            <Col md={2} style={{ padding: 0 }}>
              <Sidebar />
            </Col>

            <Col md={10}>
              <div
                style={{
                  backgroundImage: `url(${BGAnimation})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100vh",
                  width: "100%",
                }}
              >
                <Container fluid={true} className="p-3">
                  <Header />

                  <Row
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <h3>Dashboard</h3>
                  </Row>
                  <Row className="p-3">
                    {/* {mainBusinessUser === true && } */}
                    {/* <MiniWidget reports={salesReport1} /> */}
                    <Col lg={3}>
                      <Card
                        body
                        style={{
                          borderRadius: 10,
                          height: 142,
                          backgroundImage: `url(${Eventbg})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderWidth: 1,
                          borderColor: "#67B8F7",
                          shadowColor: "#6CB8F1",
                          shadowOffset: {
                            width: 0,
                            height: 6,
                          },
                          shadowOpacity: 16,
                          shadowRadius: 14,
                          elevation: 10,
                        }}
                      >
                        <h4>Events</h4>
                        <h5>
                          {eventCount !== null && eventCount !== undefined
                            ? eventCount
                            : "Loading..."}
                        </h5>
                      </Card>
                    </Col>
                    <Col lg={3}>
                      <Card
                        body
                        style={{
                          borderRadius: 10,
                          height: 142,
                          backgroundImage: `url(${Gallerybg})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderWidth: 1,
                          borderColor: "#67B8F7",
                          shadowColor: "#6CB8F1",
                          shadowOffset: {
                            width: 0,
                            height: 6,
                          },
                          shadowOpacity: 16,
                          shadowRadius: 14,
                          elevation: 10,
                        }}
                      >
                        <h4>Gallery</h4>
                        <h5>
                          {galleryCount !== null && galleryCount !== undefined
                            ? galleryCount
                            : "Loading..."}
                        </h5>
                      </Card>
                    </Col>
                    <Col lg={3}>
                      <Card
                        body
                        style={{
                          borderRadius: 10,
                          height: 142,
                          backgroundImage: `url(${Careerbg})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderWidth: 1,
                          borderColor: "#67B8F7",
                          shadowColor: "#6CB8F1",
                          shadowOffset: {
                            width: 0,
                            height: 6,
                          },
                          shadowOpacity: 16,
                          shadowRadius: 14,
                          elevation: 10,
                        }}
                      >
                        <h4>Careers</h4>
                        <h5>
                          {careerCount !== null && careerCount !== undefined
                            ? careerCount
                            : "Loading..."}
                        </h5>
                      </Card>
                    </Col>
                    <Col lg={3}>
                      <Card
                        body
                        style={{
                          borderRadius: 10,
                          height: 142,
                          backgroundImage: `url(${CMEbg})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderWidth: 1,
                          borderColor: "#67B8F7",
                          shadowColor: "#6CB8F1",
                          shadowOffset: {
                            width: 0,
                            height: 6,
                          },
                          shadowOpacity: 16,
                          shadowRadius: 14,
                          elevation: 10,
                        }}
                      >
                        <h4>CME</h4>
                        <h5>
                          {cmeCount !== null && cmeCount !== undefined
                            ? cmeCount
                            : "Loading..."}
                        </h5>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="px-3">
                    <Col xs={6} className="d-flex justify-content-center">
                      <Card
                        className="bg-primary"
                        style={{
                          height: 100,
                          borderRadius: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          margin: "0 10px",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        <h5 className="text-light mb-0">
                          Total number of onboard Doctors
                        </h5>
                        <h5 className="text-light">
                          {doctorList && doctorList.length}
                        </h5>
                      </Card>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-center">
                      <Card
                        className="bg-primary"
                        style={{
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 20,
                          textAlign: "center",
                          margin: "0 10px",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        <h5 className="text-light mb-0">
                          Total Number of Associations
                        </h5>
                        <h5 className="text-light">
                          {associationCount !== null &&
                          associationCount !== undefined
                            ? associationCount
                            : "Loading..."}
                        </h5>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="p-3">
                    <Col xl={12}>
                      <Card style={{ borderRadius: 20 }}>
                        <CardBody>
                          <Row>
                            <Col lg={4} className="h4">
                              Doctor's List
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <Table className="table table-bordered border-dark mb-0 text-center">
                              <thead>
                                <tr>
                                  <th>Sr.No</th>
                                  <th>Profile Photo</th>
                                  <th>Doctor Name</th>
                                  <th>Degree</th>
                                  <th>Contact No.</th>
                                  <th>Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentDoctors &&
                                  currentDoctors.map((doctor, index) => (
                                    <tr key={index}>
                                      <th scope="row">
                                        {indexOfFirstDoctor + index + 1}
                                      </th>
                                      <td>
                                        <img
                                          src={
                                            doctor.profileImage
                                              ? `data:image/png;base64,${doctor.profileImage}`
                                              : avatar4
                                          }
                                          className="rounded avatar-md"
                                          alt=""
                                        />
                                      </td>
                                      <td>{doctor ? doctor.name : null}</td>
                                      <td>{doctor ? doctor.degree : null}</td>
                                      <td>{doctor ? doctor.contact : null}</td>
                                      <td>{doctor ? doctor.email : null}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                            <div className="d-flex justify-content-center mt-3">
                              <Pagination className="pagination">
                                <PaginationItem disabled={currentPage === 1}>
                                  <PaginationLink
                                    previous
                                    onClick={() => paginate(currentPage - 1)}
                                  />
                                </PaginationItem>
                                {Array.from(
                                  Array(
                                    Math.ceil(
                                      doctorList.length / doctorsPerPage
                                    )
                                  ),
                                  (item, index) => (
                                    <PaginationItem
                                      active={index + 1 === currentPage}
                                      key={index}
                                    >
                                      <PaginationLink
                                        onClick={() => paginate(index + 1)}
                                      >
                                        {index + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )
                                )}
                                <PaginationItem
                                  disabled={
                                    currentPage ===
                                    Math.ceil(
                                      doctorList.length / doctorsPerPage
                                    )
                                  }
                                >
                                  <PaginationLink
                                    next
                                    onClick={() => paginate(currentPage + 1)}
                                  />
                                </PaginationItem>
                              </Pagination>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* calender yaha se start hai  */}
                    {/* <Col lg={4}>
                    <Row>
                      <Card
                        className="responsive-card"
                        style={{
                          borderRadius: 20,
                        }}
                      >
                        <CardBody>
                          <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={formattedEvents && formattedEvents} // Pass the formatted events to FullCalendar
                            slotDuration={"00:15:00"}
                            handleWindowResize={true}
                            themeSystem="bootstrap"
                            // editable={true}
                            // droppable={true}
                            // selectable={true}
                          />
                        </CardBody>
                      </Card>
                    </Row>
                    {/* <Row className="justify-content-center mt-4">
                      <Col xl={12} className="d-flex justify-content-center">
                        <Card
                          className="bg-primary"
                          style={{
                            borderRadius: 20,
                            textAlign: "center",
                            margin: "0 10px",
                            padding: "10px",
                            width: "100%",
                          }}
                        >
                          <h5 className="text-light mb-0">
                            Total number of onboard Doctors
                          </h5>
                          <h5 className="text-light">
                            {doctorList && doctorList.length}
                          </h5>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-4">
                      <Col xl={12} className="d-flex justify-content-center">
                        <Card
                          className="bg-primary"
                          style={{
                            borderRadius: 20,
                            textAlign: "center",
                            margin: "0 10px",
                            padding: "10px",
                            width: "100%",
                          }}
                        >
                          <h5 className="text-light mb-0">
                            Total Number of Associations
                          </h5>
                          <h5 className="text-light">
                            {associationCount ? associationCount : "Loading..."}
                          </h5>
                        </Card>
                      </Col>
                    </Row> 
                  </Col> */}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </div>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default Dashboard;
