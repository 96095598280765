import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  Container,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom.min";
import videocam from "../../assets/images/videocam.png";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import LoadingOverlay from "react-loading-overlay";

const CMESummery = (props) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { state } = useLocation();
  const startDate = new Date(state.startDateTime);
  const lastDate = new Date(state.lastDateTime);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");

    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  const startDateString = startDate.toLocaleString("en-US", {
    dateStyle: "full",
    timeStyle: "short",
  });
  const lastDateString = lastDate.toLocaleString("en-US", {
    dateStyle: "full",
    timeStyle: "short",
  });

  //console.log(state);

  function saveCme() {
    setIsLoading(true);
    toggle();
    const jwt = localStorage.getItem("jwt");
    console.log(localStorage.getItem("jwt"));
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);

    const data = state;
    var formdata = new FormData();
    formdata.append("image", data.coverImg);
    formdata.append("organiser", data.requestTitle);
    formdata.append("name", data.name);
    formdata.append("description", data.description);
    formdata.append("location", data.location);
    formdata.append("isOnline", data.isOnline);
    formdata.append("lastDateTime", data.lastDateTime);
    formdata.append("startDateTime", data.startDateTime);
    formdata.append("sponsor", data.requestSponsor);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${REACT_APP_API_ENDPOINT}/cme`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (result.status === 200) {
          //console.warn("resp", resp);
          setIsLoading(false);
          setModal(false);
          localStorage.setItem("isEvent", true);
          window.open("/edit-cme", "_self");
        } else {
          alert("please enter correct credentials");
        }
      });
    });
  }

  const dataObject = {
    name: state.name,
    requestTitle: state.requestTitle,
    requestSponsor: state.requestSponsor,
    description: state.description,
    location: state.location,
    isOnline: state.isOnline,
    lastDateTime: state.lastDateTime,
    startDateTime: state.startDateTime,
  };

  const imageUrl = URL.createObjectURL(state.coverImg);
  return (
    <LoadingOverlay
      active={isLoading}
      spinner={
        <Spinner style={{ position: "absolute", left: "60%" }}></Spinner>
      }
    >
      <React.Fragment>
        <div className="page-content" style={{ padding: 0 }}>
          <Row>
            <Col md={2}>
              <Sidebar />
            </Col>

            <Col md={9}>
              <Container fluid={true}>
                <Header />
                <Row
                  style={{
                    marginRight: 10,
                    marginTop: 85,
                  }}
                >
                  <Col md={2} xl={2}></Col>

                  <Col md={10} xl={10}>
                    <Card className="p-3">
                      <CardTitle
                        style={{
                          fontSize: 22,
                          fontWeight: "500",
                          marginLeft: 10,
                        }}
                      >
                        Summery
                      </CardTitle>
                      <CardBody style={{ border: "1px solid gray" }}>
                        <Row>
                          <CardImg
                            className="img-fluid mb-4"
                            src={imageUrl}
                            style={{ height: "200px", objectFit: "contain" }}
                            alt="Card image cap"
                          />
                        </Row>
                        <hr />
                        <CardSubtitle className="mb-3">
                          <Row>
                            <Col md={11}>
                              {startDateString} - {lastDateString}
                            </Col>
                            <Col
                              md={1}
                              style={{
                                width: "36px",
                                height: "36px",
                                borderRadius: 20,
                                background: "rgba(75, 75, 75, 0.4)",
                                paddingTop: 8,
                                paddingRight: 2,
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/edit-cme-summery",
                                  state: dataObject,
                                }}
                                style={{
                                  color: "black",
                                }}
                              >
                                <i className="dripicons-pencil"></i>
                              </Link>
                            </Col>
                          </Row>
                        </CardSubtitle>
                        <CardSubtitle
                          style={{
                            fontSize: 18,
                            fontWeight: "400",
                          }}
                          className="mb-1"
                        >
                          Event name: {state.name}
                        </CardSubtitle>
                        <CardSubtitle className="mb-1">
                          Organised by: {state.requestTitle}
                        </CardSubtitle>
                        <CardSubtitle className="mb-1">
                          Description: {state.description}
                        </CardSubtitle>
                        {state.requestSponsor && (
                          <CardSubtitle className="mb-1">
                            Sponsor by: {state.requestSponsor}
                          </CardSubtitle>
                        )}
                        <CardSubtitle className="mb-1">
                          <i className="dripicons-location" /> {state.location}
                        </CardSubtitle>
                        <CardSubtitle className="mb-3">
                          <img src={videocam} width={18} height={15} />{" "}
                          {state.isOnline ? "online" : "offline"}
                        </CardSubtitle>
                        <Row
                          style={{
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={saveCme}
                            style={{ width: "100px", margin: "0 auto" }}
                          >
                            Post
                          </button>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </LoadingOverlay>
  );
};
export default CMESummery;
