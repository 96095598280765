import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Spinner,
  Modal,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Form from "react-bootstrap/Form";
import { Button } from "reactstrap";
import * as XLSX from "xlsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import QRCode from "qrcode.react";
import LoadingOverlay from "react-loading-overlay";

const Doctors = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  // const { REACT_APPPOINT } = process.env;
  const REACT_APPPOINT = "http://192.168.0.116:3000";
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_backdrop1, setmodal_backdrop1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [associationName, setAssociationName] = useState("");
  const [degree, setDegree] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [demail, setdEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [yoe, setYoe] = useState("");
  const [successfulOT, setSuccessfulOT] = useState("");
  const [patientRecovered, setPatientRecovered] = useState("");
  const [certificatesAchieved, setCertificatesAchieved] = useState("");
  const [association, setAssociation] = useState();
  const [email, setEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [File, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [selectedfile, setSelectedFile] = useState();
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");

    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // add doctor in the list POST API
  function addDoctor() {
    if (selectedfile && selectedfile.size > MAX_IMAGE_SIZE) {
      alert("Please upload image upto 100KB in size.");
      setSelectedFile();
      return;
    }
    if (
      name &&
      contact &&
      demail &&
      degree &&
      designation &&
      yoe &&
      successfulOT &&
      patientRecovered &&
      certificatesAchieved
    ) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const isValidEmail = emailPattern.test(demail);
      const mobileNumberPattern = /^[0-9]{10}$/;
      const isValidMobileNumber = mobileNumberPattern.test(contact);
      if (isValidEmail && isValidMobileNumber) {
        if (association && association._id) {
          var myHeaders = new Headers();
          const jwt = localStorage.getItem("jwt");
          myHeaders.append("x-api-key", jwt);
          const formData = new FormData();
          formData.append("association", association._id);
          formData.append("degree", degree);
          formData.append("name", name);
          formData.append("contact", contact);
          formData.append("email", demail);
          formData.append("designation", designation);

          formData.append("yoe", yoe);

          formData.append("coverImg", selectedfile);
          formData.append("successfulOT", successfulOT);
          formData.append("patientRecovered", patientRecovered);
          formData.append("certificatesAchieved", certificatesAchieved);

          fetch(`${REACT_APP_API_ENDPOINT}/doctor`, {
            method: "POST",
            headers: myHeaders,
            body: formData,
          }).then((result) => {
            console.warn("result", result);
            result.json().then((resp) => {
              if (result.status === 200) {
                console.warn("resp", resp);
                localStorage.setItem("isDoctor", true);
                window.open("/doctors", "_self");
              } else {
                alert("please enter correct credentials");
              }
            });
          });
        } else {
          console.log("association not found");
        }
      } else {
        alert("Please enter valid Email/Mobile!");
      }
    } else {
      alert("Please fill required fields!");
    }
  }
  // Get doctor in the list
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    if (email) {
      fetch(
        `${REACT_APP_API_ENDPOINT}/president/association?email=${email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setAssociation(result))
        .catch((error) => console.log("error", error));
    }
  }, [email]);

  useEffect(() => {
    setIsLoading(true);
    if (association && association._id) {
      fetch(
        `${REACT_APP_API_ENDPOINT}/association/doctors?association=${association._id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
          setDoctor(result);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error", error);
        });
    }
  }, [association]);

  // Delete doctor from the list
  function deleteDoctor(_id) {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      doctorId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(_id);
    fetch(`${REACT_APP_API_ENDPOINT}/doctor`, requestOptions).then((result) => {
      result.json().then((response) => {
        // console.warn(response);

        window.open("/doctors", "_self");
      });
    });
  }

  // Association k dropdown  liye calling get api here
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // chaning of profile k liye useeffect lagate hai
  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/user/profile`, {
      method: "GET",
      headers: { "x-api-key": jwt },
    })
      .then((response) => response.json())
      .then((result) => {
        setEmail(result.email);
      })
      .catch((error) => console.log("error", error));
  }, [jwt]);

  // Adding doctor's in bulk from excel
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (event.target.files[0] !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        if (jsonData[0].length !== 9) {
          alert("Please select valid excel sheet!");
          setFile();
          return;
        }
        const headers = jsonData[0];
        const parsedData = jsonData
          .slice(1)
          .map((row) =>
            headers.reduce(
              (obj, header, index) => ({ ...obj, [header]: row[index] }),
              {}
            )
          );
        //console.log("pd", parsedData);
        // Validate the parsed data
        const requiredFields = ["name", "email"];
        const hasRequiredFields = parsedData.every((obj) =>
          requiredFields.every((field) => obj.hasOwnProperty(field))
        );

        if (hasRequiredFields) {
          const doctorsData = parsedData.map((obj) => ({
            degree: obj.degree,
            name: obj.name,
            contact: obj.contact,
            email: obj.email,
            designation: obj.designation,
            yoe: obj.yoe,
            successfulOT: obj.successfulOT,
            patientRecovered: obj.patientRecovered,
            certificatesAchieved: obj.certificatesAchieved,
            association: association._id,
            coverImg: "",
          }));
          setDoctors(doctorsData);
          console.log(doctorsData);
        } else {
          console.error("Parsed data is missing required fields");
          // Display an error message to the user
        }
      };

      reader.onerror = (e) => {
        console.error("File could not be read! Code " + e.target.error.code);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleUploadDoctors = () => {
    setOverlay(true);

    if (!doctors || doctors.length === 0) {
      alert("No doctors data to upload");
      setOverlay(false);
      return;
    }

    const uploadPromises = doctors.map((element) => {
      return fetch(`${REACT_APP_API_ENDPOINT}/doctor`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(element),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // Handle success or display any error messages
        })
        .catch((error) => {
          console.error(error);
          // Handle error or display any error messages
          throw error; // Propagate the error to the next `catch`
        });
    });

    Promise.all(uploadPromises)
      .then(() => {
        setOverlay(false);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          window.location.reload(); // Reload the page after 2 seconds
        }, 2000); // 2 seconds
      })
      .catch((error) => {
        setOverlay(false);
        alert(error);
      });
  };

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop1() {
    setmodal_backdrop1(!modal_backdrop1);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredList = doctor.filter((doctor) =>
    doctor.name.toLowerCase().includes(searchName.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
  const [membership, setMemebership] = useState("null");
  console.log(REACT_APPPOINT);
  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/membership/plan`, {
      method: "GET",
      headers: { "x-api-key": jwt },
    })
      .then((response) => response.json())
      .then((result) => {
        setMemebership(result);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  }, [doctor]);

  //console.log(doctor);
  return (
    <LoadingOverlay active={overlay} spinner={<Spinner></Spinner>}>
      <React.Fragment>
        <div className="page-content" style={{ padding: 0 }}>
          <Row>
            <Col md={2}>
              <Sidebar />
            </Col>
            <Col md={10}>
              <Container fluid={true}>
                <Header />

                <Row
                  style={{
                    marginTop: 85,
                  }}
                >
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <CardTitle>
                          {" "}
                          <h3>Doctors</h3>
                        </CardTitle>
                        {isLoading ? (
                          <Button
                            color="primary"
                            style={{
                              position: "absolute",
                              top: 200,
                              left: "50%",
                            }}
                            disabled
                          >
                            <Spinner size="sm">Loading...</Spinner>
                            <span> Loading</span>
                          </Button>
                        ) : (
                          <>
                            <Row>
                              <Col lg={2} className="mb-2">
                                <Button id="caret" color="primary">
                                  Total No. of Doctors <br />
                                  <h4 style={{ color: "#fff" }}>
                                    {doctor && doctor.length}
                                  </h4>
                                </Button>{" "}
                              </Col>
                              <Col lg={4}>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={searchName}
                                  onChange={(e) =>
                                    setSearchName(e.target.value)
                                  }
                                  placeholder="Search by name"
                                />
                              </Col>
                              <Col lg={2}>
                                <div>
                                  <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileUpload}
                                  />
                                  <Button
                                    color="primary"
                                    className={`${
                                      File !== undefined ? "" : "disabled"
                                    }`}
                                    style={{ marginTop: 20 }}
                                    onClick={handleUploadDoctors}
                                  >
                                    Upload Doctors Excel
                                  </Button>
                                  {showPopup && (
                                    <div className="popup">
                                      <p>Doctors uploaded successfully!</p>
                                    </div>
                                  )}
                                </div>
                              </Col>

                              <Col
                                md={2}
                                className="d-flex justify-content-end align-items-center"
                              >
                                <div className="mt-1">
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                      tog_backdrop();
                                    }}
                                    data-toggle="modal"
                                    style={{ marginBottom: 10 }}
                                  >
                                    <i className="dripicons-plus" /> Add Doctor
                                  </button>

                                  <Modal
                                    isOpen={modal_backdrop}
                                    toggle={() => {
                                      tog_backdrop();
                                    }}
                                    scrollable={true}
                                    id="staticBackdrop"
                                  >
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="staticBackdropLabel"
                                      >
                                        Add a new doctor
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                          setmodal_backdrop(false);
                                        }}
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <Form>
                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-form-label"
                                            >
                                              Name*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                name="name"
                                                onChange={(e) =>
                                                  setName(e.target.value)
                                                }
                                                value={name}
                                                type="text"
                                                required
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-form-label"
                                            >
                                              Contact No.*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="text"
                                                value={contact}
                                                required={true}
                                                onChange={(e) =>
                                                  setContact(e.target.value)
                                                }
                                                maxLength={10}
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-2 col-form-label"
                                            >
                                              Degree*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="text"
                                                value={degree}
                                                onChange={(e) =>
                                                  setDegree(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-2 col-form-label"
                                            >
                                              Email*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="email"
                                                value={demail}
                                                required={true}
                                                onChange={(e) =>
                                                  setdEmail(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-2 col-form-label"
                                            >
                                              Designation*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="text"
                                                value={designation}
                                                required={true}
                                                onChange={(e) =>
                                                  setDesignation(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-4 col-form-label"
                                            >
                                              Years of experience*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="number"
                                                value={yoe}
                                                onChange={(e) =>
                                                  setYoe(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-3 col-form-label"
                                            >
                                              Successful OT*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="number"
                                                value={successfulOT}
                                                onChange={(e) =>
                                                  setSuccessfulOT(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-4 col-form-label"
                                            >
                                              Patient Recovered*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="number"
                                                value={patientRecovered}
                                                onChange={(e) =>
                                                  setPatientRecovered(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-4 col-form-label"
                                            >
                                              Certificates Achieved*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="number"
                                                value={certificatesAchieved}
                                                required={true}
                                                onChange={(e) =>
                                                  setCertificatesAchieved(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="mb-1">
                                            <label
                                              htmlFor="example-text-input"
                                              className="col-md-2 col-form-label"
                                            >
                                              Profile Image*
                                            </label>
                                            <div>
                                              <input
                                                className="form-control"
                                                type="file"
                                                onChange={(e) =>
                                                  setSelectedFile(
                                                    e.target.files[0]
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className={`btn btn-primary ${
                                          name &&
                                          contact &&
                                          degree &&
                                          demail &&
                                          designation &&
                                          yoe &&
                                          successfulOT &&
                                          patientRecovered &&
                                          certificatesAchieved &&
                                          selectedfile !== undefined
                                            ? ""
                                            : "disabled"
                                        }`}
                                        onClick={addDoctor}
                                      >
                                        Done
                                      </button>
                                    </div>
                                  </Modal>
                                </div>
                              </Col>
                              {association && REACT_APPPOINT && association ? (
                                <Col lg={2} className="mb-2">
                                  <QRCode
                                    value={
                                      REACT_APPPOINT +
                                      "/add-doctor?aid=" +
                                      association._id
                                    }
                                  />
                                </Col>
                              ) : null}
                            </Row>
                            {doctor.length > 0 ? (
                              <>
                                <div className="table-responsive">
                                  <table className="table table-bordered border-dark mb-0 text-center">
                                    <thead>
                                      <tr>
                                        <th>Sr.No</th>
                                        <th>Profile Photo</th>
                                        <th>Doctor Name</th>
                                        <th>Degree</th>
                                        <th>Designation</th>
                                        {/*<th>SuccessfulOT</th>*/}
                                        <th>Contact No.</th>
                                        <th>Membership</th>
                                        <th>Membership status</th>
                                        <th>QR Code</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {currentItems &&
                                        currentItems.map((index, id) => (
                                          <tr key={id}>
                                            <th scope="row">
                                              {(currentPage - 1) *
                                                itemsPerPage +
                                                id +
                                                1}
                                            </th>
                                            <td>
                                              <img
                                                src={
                                                  index.profileImage
                                                    ? `data:image/png;base64,${index.profileImage}`
                                                    : avatar4
                                                }
                                                className="rounded avatar-md"
                                                alt="Doctor"
                                              />
                                            </td>
                                            <td>{index ? index.name : null}</td>
                                            <td>
                                              {index ? index.degree : null}
                                            </td>
                                            <td>
                                              {index ? index.designation : null}
                                            </td>
                                            {/*<td>
                                      {index ? index.successfulOT : null}+
                                </td>*/}
                                            <td>
                                              {index ? index.contact : null}
                                            </td>
                                            <td className="text-dark">
                                              {index.plan.name}
                                            </td>
                                            <td className="text-success">
                                              {" "}
                                              Running
                                            </td>
                                            <td>
                                              <QRCode
                                                value={JSON.stringify({
                                                  ...index,
                                                  profileImage: "",
                                                })}
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                color="danger"
                                                className="btn btn-danger waves-effect waves-light"
                                                onClick={() =>
                                                  deleteDoctor(
                                                    index && index._id
                                                  )
                                                }
                                              >
                                                Remove
                                              </Button>{" "}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                  <Pagination className="pagination">
                                    <PaginationItem
                                      disabled={currentPage === 1}
                                    >
                                      <PaginationLink
                                        previous
                                        onClick={() =>
                                          paginate(currentPage - 1)
                                        }
                                      />
                                    </PaginationItem>
                                    {Array.from(
                                      Array(
                                        Math.ceil(
                                          filteredList.length / itemsPerPage
                                        )
                                      ),
                                      (item, index) => (
                                        <PaginationItem
                                          active={index + 1 === currentPage}
                                          key={index}
                                        >
                                          <PaginationLink
                                            onClick={() => paginate(index + 1)}
                                          >
                                            {index + 1}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )
                                    )}
                                    <PaginationItem
                                      disabled={
                                        currentPage ===
                                        Math.ceil(
                                          filteredList.length / itemsPerPage
                                        )
                                      }
                                    >
                                      <PaginationLink
                                        next
                                        onClick={() =>
                                          paginate(currentPage + 1)
                                        }
                                      />
                                    </PaginationItem>
                                  </Pagination>
                                </div>
                              </>
                            ) : (
                              <Row>
                                <Col md={4}></Col>
                                <Col md={4}>
                                  <h4>Nothing to show here..!</h4>
                                </Col>
                                <Col md={4}></Col>
                              </Row>
                            )}
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </LoadingOverlay>
  );
};

export default Doctors;
